import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react components for routing our app without refresh
import { Link } from "gatsby";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
// core components
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomButton from "components/CustomButtons/CustomButton.jsx";
import ButtonTeam from "components/CustomButtons/ButtonTeam.jsx";
import ButtonWWD from "components/CustomButtons/ButtonWWD.jsx";
import ButtonColliery from "components/CustomButtons/ButtonColliery.jsx";
import DownloadButton from "components/CustomButtons/DownloadButton.jsx";
import SpecButton from "components/CustomButtons/SpecButton.jsx";

import Parallax from "components/Parallax/Parallax.jsx";
// sections for this page
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import SectionBasics from "./Sections/SectionBasics.jsx";
import SectionNavbars from "./Sections/SectionNavbars.jsx";
import SectionTabs from "./Sections/SectionTabs.jsx";
import SectionPills from "./Sections/SectionPills.jsx";
import SectionNotifications from "./Sections/SectionNotifications.jsx";
import SectionTypography from "./Sections/SectionTypography.jsx";
import SectionJavascript from "./Sections/SectionJavascript.jsx";
import SectionCarousel from "./Sections/SectionCarousel.jsx";
import SectionCompletedExamples from "./Sections/SectionCompletedExamples.jsx";
import SectionLogin from "./Sections/SectionLogin.jsx";
import SectionExamples from "./Sections/SectionExamples.jsx";
import SectionDownload from "./Sections/SectionDownload.jsx";
import ProductSection from "./Sections/ProductSection.jsx";

import portrait from "../../assets/img/portrait2.png";
import specshome from "../../assets/img/specshome.png";
import meetteam from "../../assets/img/meetteam.jpg";
import wwd from "../../assets/img/wwd.jpg";
import footimg from "../../assets/img/footimg.png";
import image from "../../assets/img/footimg.png";
import colliery from "../../assets/img/colliery.png";

import componentsStyle from "assets/jss/material-kit-react/views/components.jsx";

class Components extends React.Component {
  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
        <Header
          brand="Material Kit React"
          rightLinks={<HeaderLinks />}
          fixed
          color="transparent"
          changeColorOnScroll={{
            height: 400,
            color: "white"
          }}
          {...rest}
        />
        <Parallax image={require("assets/img/HomepageHero.png")}>
          <div className={classes.container}>
            <GridContainer>
              <GridItem>
                <div className={classes.brand}>
                <div className={classes.intro}>
                  Welcome to
                  </div>
                  <h1 className={classes.title}>Virtuous Circle</h1>
                  <div className={classes.subtitle}>
                  Commercial Real Estate investment and development.

                  </div>
                <CustomButton/>
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </Parallax>

        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.mainwrap}>
          {/* <ProductSection /> */}
          <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
          <div className={classes.preheading}>
          Why Virtuous Circle Developments?
                  </div>
                 
                <div className={classes.parawrap}>
                <p className={classes.paratext}>
                Working with occupiers, landowners and investors, we deliver efficient, simple and focused solutions to meet our clients’ real estate and investment requirements. Our global network of experienced, nimble and solutions-led professionals allows us to deliver best in class answers in competitive local markets.
                </p>
            
              </div>
                
            </GridItem>
            </GridContainer>

            </div>  
          </div>

        <div className={classNames(classes.mainlinks, classes.mainRaisedlinks)}>

            <div className={classes.mainwraplinks}>
            <GridContainer>
            <GridItem xs={12} sm={6} md={6}>
          <div className={classes.imagewrap}>
          <img alt="..." src={meetteam} className={classes.homeimage} />
          </div>
          <div className={classes.parawraplinks}>
          <ButtonTeam/>
                <p className={classes.paratextlinks}>
                Find out more about our team of experienced real estate professionals.
                </p>
                
              </div>
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
          <div className={classes.imagewrap}>
          <img alt="..." src={wwd} className={classes.homeimage} />
          </div>
          <div className={classes.parawraplinks}>
          <ButtonWWD/>
                <p className={classes.paratextlinks}>
                Asset management, development, build, investment and equity services.
                </p>
            
              </div>
            </GridItem>
            
            </GridContainer>

            </div>   
        </div>

        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.mainwrapfoot}>
          {/* <ProductSection /> */}

          <div className={classes.bgimg}       style={{
            backgroundImage: "url(" + image + ")",
            backgroundSize: "cover",
            backgroundPosition: "center center"
          }}>
                    <div className={classes.container}>
            <GridContainer>
              <GridItem>
                <div className={classes.brandfooter}>
                  <div className={classes.footerimagewrap}>
          <img alt="..." src={colliery} className={classes.colliery} />
          </div>
                <ButtonColliery/>
                </div>
              </GridItem>
            </GridContainer>
          </div>
          </div>

            </div>  
          </div>

        <Footer />
      </div>
    );
  }
}

export default withStyles(componentsStyle)(Components);
